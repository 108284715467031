import { PHOTO_UPLOAD_URL } from 'api/constants';
import { getImageOptions } from 'utils/api';

function dataURIToBlob(dataURI) {
  const splitDataURI = dataURI.split(',');
  const byteString = splitDataURI[0].indexOf('base64') >= 0 ? atob(splitDataURI[1]) : decodeURI(splitDataURI[1]);
  const mimeString = splitDataURI[0].split(':')[1].split(';')[0];

  const ia = new Uint8Array(byteString.length);
  for (let i = 0; i < byteString.length; i++) {
    ia[i] = byteString.charCodeAt(i);
  }

  return new Blob([ia], { type: mimeString });
}

export const getPhotoUploadApiConfig = (src) => {
  const blob = dataURIToBlob(src);
  const formData = new FormData();
  formData.append('file', blob);
  return {
    path: PHOTO_UPLOAD_URL,
    options: getImageOptions(formData),
  };
};
