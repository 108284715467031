import { PRESIGN_SIGNATURES_PATH } from 'api/constants';
import { getPresignCredentials, getOptions } from 'utils/api';

export const getSignatureApiConfig = (id, queryParams, agreementId) => {
  const credentials = getPresignCredentials(id, queryParams);
  const body = {
    agreement_id: agreementId,
    signed_at: new Date().toISOString(),
  };
  const options = getOptions('POST', credentials, JSON.stringify(body));

  return {
    path: PRESIGN_SIGNATURES_PATH,
    options,
  };
};
