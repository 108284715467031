import { PRESIGN_VISITOR_PATH } from 'api/constants';
import { getOptions, getPresignCredentials } from 'utils/api';

export const getVisitorApiConfig = (id, queryParams) => {
  const credentials = getPresignCredentials(id, queryParams);
  const options = getOptions('GET', credentials);

  return {
    path: PRESIGN_VISITOR_PATH,
    options,
  };
};
