import React, { useState } from 'react';
import { useQueryParams, useRoutes } from 'hookrouter';

import PresignVisitorForm from 'components/presign/PresignVisitorForm';
import PresignPhoto from 'components/presign/PresignPhoto';
import PresignCovidWaiver from 'components/presign/PresignCovidWaiver';
import PresignConfirmation from 'components/presign/PresignConfirmation';
import NotFound from 'components/common/NotFound/NotFound';
import { usePresignValidation } from 'hooks/presign/usePresignValidation';
import { usePresignNavigation } from 'hooks/presign/usePresignNavigation';
import { usePhotoUploadApi } from 'hooks/usePhotoUploadApi';
import { usePhotoApi } from 'hooks/presign/usePhotoApi';
import { parseIdFromUrl } from 'utils/url';
import { ReactComponent as Loader } from 'assets/loader.svg';
import { prepareVisitDetails } from 'components/shared/VisitDetails/VisitDetails';
import { useSignatureApi } from 'hooks/presign/useSignatureApi';
import { parseRegistration, parseVisitor, useVisitorApi } from 'hooks/presign/useVisitorApi';
import { checkIsPresignPhotoComplete } from 'utils/photo';
import { checkIsCovidWaiverSigned, checkIsAgreementsStepSkipped } from 'utils/agreement';
import { useLocalization } from 'i18n';

const routes = {
  '/': () => (props) => <PresignVisitorForm {...props} />,
  '/photo': () => (props) => <PresignPhoto {...props} />,
  '/agreement': () => (props) => <PresignCovidWaiver {...props} />,
  '/confirmation': () => (props) => (
    <PresignConfirmation
      id={props.id}
      locationId={props.location.id}
      qrEnabled={props.location.qrEnabled}
      visitDetails={prepareVisitDetails(props)}
    />
  ),
};

const Presign = () => {
  const registrationId = parseIdFromUrl();
  const [queryParams] = useQueryParams();
  const [isDateValid, setIsDateValid] = useState(false);
  const [isCovidWaiverPassed, setIsCovidWaiverPassed] = useState(false);
  const [photo, setPhoto] = useState({ src: null, takenAt: null, submitted: false, skipped: false });
  const [isVisitorFormPassed, setIsVisitorFormPassed] = useState(false);

  const { setupData, setupError, isSetupLoading } = useVisitorApi(registrationId, queryParams);
  const { signatureResponse, signatureError, sendingSignature, callSignatureApi } = useSignatureApi();
  const registration = parseRegistration(setupData);
  const visitor = parseVisitor(setupData);
  useLocalization(registration?.location?.locale);
  const isCovidWaiverSigned =
    checkIsCovidWaiverSigned(registration?.location?.agreements, registration?.signatures) ||
    Boolean(signatureResponse);
  const shouldSkipAgreementsStep = checkIsAgreementsStepSkipped(
    registration?.location?.agreements,
    registration?.location?.agreementsEnabled,
  );
  const isCovidWaiverComplete = isCovidWaiverSigned || isCovidWaiverPassed || shouldSkipAgreementsStep;
  const { error: validationError } = usePresignValidation(setupData, setIsDateValid);
  const { uploadedPhotoResponse, uploadingPhoto, uploadedPhotoError } = usePhotoUploadApi(isCovidWaiverComplete, photo);
  const { photoResponse, sendingPhoto, photoError } = usePhotoApi(
    registrationId,
    queryParams,
    uploadedPhotoResponse,
    photo.takenAt,
  );
  const isPhotoComplete = checkIsPresignPhotoComplete(photo, registration, visitor) || Boolean(photoResponse);

  usePresignNavigation(setupData, isVisitorFormPassed, isPhotoComplete, isCovidWaiverComplete, queryParams);

  const match = useRoutes(routes);
  const sendingData = sendingSignature || uploadingPhoto || sendingPhoto;
  const loader = isSetupLoading && <Loader className="loader" />;
  const notFound = (setupError || signatureError || validationError || uploadedPhotoError || photoError || !match) && (
    <NotFound />
  );
  return (
    loader ||
    notFound ||
    match({
      ...registration,
      photo,
      setPhoto,
      isDateValid,
      callSignatureApi,
      sendingData,
      setIsVisitorFormPassed,
      setIsCovidWaiverPassed,
    })
  );
};

export default Presign;
