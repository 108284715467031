import { useEffect } from 'react';
import { navigate } from 'hookrouter';

export const usePresignNavigation = (
  setupData,
  isVisitorFormPassed,
  isPhotoComplete,
  isCovidWaiverComplete,
  queryParams,
) => {
  useEffect(() => {
    if (!setupData) {
      return;
    }
    if (isPhotoComplete && isCovidWaiverComplete) {
      navigate('/confirmation', true, queryParams);
    } else if (isVisitorFormPassed) {
      if (isPhotoComplete) {
        navigate('/agreement', true, queryParams);
      } else {
        navigate('/photo', true, queryParams);
      }
    } else {
      navigate('/', true, queryParams);
    }
  }, [setupData, isVisitorFormPassed, isPhotoComplete, isCovidWaiverComplete, queryParams]);
};
