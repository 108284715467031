export const trackView = (sourceScreen, objectDetails) => {
  window.analytics.page('view', {
    label: 'view_screen',
    source_screen: sourceScreen,
    tool: 'visitorapp_web',
    object_details: objectDetails,
  });
};

export const trackInteraction = (sourceScreen, objectDetails, label = 'tap_button', action = 'tap') => {
  window.analytics.track('interaction', {
    label,
    source_screen: sourceScreen,
    action,
    tool: 'visitorapp_web',
    object_details: objectDetails,
  });
};
