import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import 'components/common/Photo/PhotoUpload.scss';
import { ReactComponent as ClearScreenshot } from 'components/common/Photo/assets/x.svg';

const PhotoUpload = (props) => {
  const { photoSrc, setPhoto, trackTakePhoto, trackRetake } = props;
  const { t } = useTranslation();

  const handleUpload = (event) => {
    trackTakePhoto();

    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener('load', (e) => {
      const src = e.target.result;
      const takenAt = new Date().toISOString();
      setPhoto({ src, takenAt, submitted: false, skipped: false });
    });
    fileReader.readAsDataURL(file);
  };

  const clearScreenshot = useCallback(() => {
    trackRetake();

    setPhoto({ src: null, takenAt: null, submitted: false, skipped: false });
  }, [setPhoto, trackRetake]);

  return (
    <div>
      <div className="image-upload">
        <input
          type="file"
          id="image"
          accept="image/gif, image/jpeg, image/png"
          onChange={handleUpload}
          capture="user"
          style={{ display: 'none' }}
        />
        <div className="image-upload__box">
          {photoSrc ? (
            <div className="image-upload__screenshot" style={{ backgroundImage: `url(${photoSrc})` }}>
              <ClearScreenshot className="photo-capture__clear-screenshot" onClick={clearScreenshot} />
            </div>
          ) : (
            <label className="ray-link" htmlFor="image">
              <div className="image-upload__placeholder">{t('photo.upload_placeholder')}</div>
            </label>
          )}
        </div>
      </div>
    </div>
  );
};

PhotoUpload.propTypes = {
  photoSrc: PropTypes.string,
  setPhoto: PropTypes.func,
  trackTakePhoto: PropTypes.func,
  trackRetake: PropTypes.func,
};

export default PhotoUpload;
