import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { useQueryParams } from 'hookrouter';
import { useTrackViewCheckIn } from 'hooks/useTrackView';
import { trackInteraction } from 'services/analytics';
import { getLookupApiConfig } from 'api/config/checkIn/getLookupApiConfig';
import VisitorForm from 'components/shared/VisitForm/VisitorForm';

const isVisitorFieldRequired = (type, visitorFields) => {
  const visitorField = visitorFields.find((field) => field.type === type);
  return visitorField ? visitorField.required : false;
};

export const CheckInVisitorForm = (props) => {
  const {
    id,
    deviceId,
    displayName,
    photoUrl,
    visitorFields,
    setVisitorName,
    lookingUp,
    callLookupApi,
    registrationId,
  } = props;
  const { t } = useTranslation();
  const [queryParams] = useQueryParams();

  const ctaText = t('next');
  const referrer = 'ipad_QR_code';

  const onSubmit = ({ firstName, lastName }) => {
    const objectDetails = {
      location_id: id,
      device_id: deviceId,
      is_guest_pre_checked: false,
      registration_id: registrationId,
      object_identifier: ctaText,
    };
    trackInteraction('visitor_form', objectDetails);

    const firstNameTrimmed = firstName.trim();
    const lastNameTrimmed = lastName.trim();
    setVisitorName({ firstName: firstNameTrimmed, lastName: lastNameTrimmed });
    const config = getLookupApiConfig(deviceId, queryParams, firstNameTrimmed, lastNameTrimmed);
    callLookupApi(config);
  };

  useTrackViewCheckIn('visitor_form', id, deviceId, registrationId, referrer);

  const variables = {
    displayName,
    photoUrl,
    firstNameRequired: isVisitorFieldRequired('fname', visitorFields),
    lastNameRequired: isVisitorFieldRequired('lname', visitorFields),
    registrationType: 'check-in',
    isSending: lookingUp,
    ctaText,
    onSubmit,
  };

  return <VisitorForm {...variables} />;
};

CheckInVisitorForm.propTypes = {
  id: PropTypes.string,
  deviceId: PropTypes.string,
  displayName: PropTypes.string,
  photoUrl: PropTypes.string,
  visitorFields: PropTypes.arrayOf(
    PropTypes.shape({
      type: PropTypes.string,
      required: PropTypes.bool,
    }),
  ),
  setVisitorName: PropTypes.func,
  lookingUp: PropTypes.bool,
  callLookupApi: PropTypes.func,
  registrationId: PropTypes.string,
};

export default CheckInVisitorForm;
