import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import 'components/shared/VisitDetails/VisitDetails.scss';
import { formatVisitDate, getFormattedUTCTime } from 'utils/date';
import { formatEnToOrdinal } from 'utils/num';

export const prepareVisitDetails = ({ host, location, expectedAt }) => {
  return { host, location, expectedAt };
};

const formatFloor = (floor, t, locale) => {
  if (!floor) {
    return t('visit_details.lobby');
  }
  const floorNumber = Number(floor);
  if (Number.isNaN(floorNumber)) {
    return t('visit_details.floor', { floor });
  }
  return t('visit_details.floor_number', { floor: formatEnToOrdinal(floorNumber, locale) });
};

const VisitDetails = (props) => {
  const {
    host: { firstName, lastName },
    location: { locale, timezone, landingFloorEnabled, landingAreaFloor, address },
    expectedAt,
  } = props;
  const { t } = useTranslation();
  const rawDate = new Date(expectedAt);
  const date = formatVisitDate(rawDate, locale, timezone);
  const time = getFormattedUTCTime(rawDate, locale, timezone);

  return (
    <div className="visit-details">
      <div className="visit-details__separator hide-phone" />
      <ul className="visit-details__info">
        <li className="visit-details__info-item">
          <span className="ray-fieldset__legend">{t('visit_details.host')}</span>
          <span className="ray-text--body-large">{`${firstName} ${lastName}`}</span>
        </li>
        <li className="visit-details__info-item">
          <span className="ray-fieldset__legend">{t('visit_details.when')}</span>
          <span className="ray-text--body-large">{date}</span>
        </li>
        <li className="visit-details__info-item">
          <span className="ray-fieldset__legend">{t('visit_details.time')}</span>
          <span className="ray-text--body-large visit-details__time">{time}</span>
        </li>
        <li className="visit-details__info-item">
          <span className="ray-fieldset__legend">{t('visit_details.where')}</span>
          <span className="ray-text--body-large">
            <a
              className="visit-details__building-link"
              href={`https://maps.google.com/maps?q=${encodeURIComponent(address)}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              {landingFloorEnabled && <span>{formatFloor(landingAreaFloor, t, locale)}</span>}
              <span>{address}</span>
            </a>
          </span>
        </li>
      </ul>
    </div>
  );
};

VisitDetails.propTypes = {
  expectedAt: PropTypes.string,
  host: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
  }),
  location: PropTypes.shape({
    locale: PropTypes.string,
    timezone: PropTypes.string,
    landingFloorEnabled: PropTypes.bool,
    landingAreaFloor: PropTypes.string,
    address: PropTypes.string,
  }),
};

export default VisitDetails;
