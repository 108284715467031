import { CHECK_IN_LOOKUP_PATH } from 'api/constants';
import { getOptions, getCheckInCredentials } from 'utils/api';

export const getLookupApiConfig = (deviceId, queryParams, firstName, lastName) => {
  const path = `${CHECK_IN_LOOKUP_PATH}?first_name=${firstName}&last_name=${lastName}`;
  const credentials = getCheckInCredentials(deviceId, queryParams);
  const options = getOptions('GET', credentials);

  return {
    path,
    options,
  };
};
