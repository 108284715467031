import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Photo from 'components/common/Photo/Photo';
import { useTrackViewPresign } from 'hooks/useTrackView';
import { trackInteraction } from 'services/analytics';

const PresignPhoto = (props) => {
  const {
    id,
    photo,
    setPhoto,
    isSending,
    photoRequired,
    location: { id: locationId },
  } = props;

  const [isCameraDisabled, setCameraDisabled] = useState(false);

  useTrackViewPresign('photo', id, locationId);

  const commonDetails = {
    registration_id: id,
    location_id: locationId,
    is_guest_pre_checked: true,
  };

  const skipPhoto = () => {
    const objectDetails = {
      ...commonDetails,
      object_identifier: 'skip',
      camera_access: isCameraDisabled ? 'disabled' : 'enabled',
    };
    trackInteraction('photo', objectDetails);

    setPhoto({ src: null, takenAt: null, submitted: false, skipped: true });
  };

  const submitPhoto = () => {
    const objectDetails = {
      ...commonDetails,
      object_identifier: 'next',
    };
    trackInteraction('photo', objectDetails, 'capture_picture', 'take_photo');

    setPhoto({ ...photo, submitted: true });
  };

  const trackTakePhoto = () => {
    const objectDetails = {
      ...commonDetails,
      object_identifier: 'takePhoto',
    };
    trackInteraction('photo', objectDetails);
  };

  const trackRetake = () => {
    const objectDetails = {
      ...commonDetails,
      object_identifier: 'retake',
    };
    trackInteraction('photo', objectDetails, 'capture_picture', 'take_photo');
  };

  const variables = {
    photo,
    setPhoto,
    isSending,
    photoRequired,
    registrationType: 'presign',
    skipPhoto,
    submitPhoto,
    setCameraDisabled,
    trackTakePhoto,
    trackRetake,
  };

  return <Photo {...variables} />;
};

PresignPhoto.propTypes = {
  id: PropTypes.string,
  photo: PropTypes.shape({
    src: PropTypes.string,
  }),
  setPhoto: PropTypes.func,
  isSending: PropTypes.bool,
  photoRequired: PropTypes.bool,
  location: PropTypes.shape({
    id: PropTypes.string,
  }),
};

export default PresignPhoto;
