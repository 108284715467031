export const domain = process.env.REACT_APP_API_URL;
export const uploads = process.env.REACT_APP_UPLOADS_API_URL;
export const envAuth = process.env.REACT_APP_ENV_AUTH;

export const PRESIGN_VISITOR_PATH = `${domain}/api/access/presign/v1/registration/visitor`;
export const PRESIGN_SIGNATURES_PATH = `${domain}/api/access/presign/v1/signatures`;
export const PRESIGN_PHOTOS_PATH = `${domain}/api/access/presign/v1/photos`;

export const CHECK_IN_SETTINGS_PATH = `${domain}/api/access/wsi/v1/settings`;
export const CHECK_IN_LOOKUP_PATH = `${domain}/api/access/wsi/v1/signin/lookup`;
export const CHECK_IN_SIGN_IN_PATH = `${domain}/api/access/wsi/v1/signin`;

export const PHOTO_UPLOAD_URL = `${uploads}/v1/images/visitors`;
