import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Loader } from 'assets/loader.svg';
import { ReactComponent as ClearScreenshot } from 'components/common/Photo/assets/x.svg';
import shutter from 'components/common/Photo/assets/shutter.png';
import 'components/common/Photo/PhotoCapture.scss';

const videoConstraints = {
  facingMode: 'user',
  aspectRatio: 3 / 4,
};

const PhotoCapture = (props) => {
  const { photoSrc, setPhoto, setCameraDisabled, trackTakePhoto, trackRetake } = props;
  const { t } = useTranslation();
  const [isLoading, setIsLoading] = useState(true);
  const [errorText, setErrorText] = useState('');
  const webcamRef = React.useRef(null);

  const captureScreenshot = useCallback(() => {
    trackTakePhoto();

    const src = webcamRef.current.getScreenshot();
    const takenAt = new Date().toISOString();
    setPhoto({ src, takenAt, submitted: false, skipped: false });
  }, [webcamRef, setPhoto, trackTakePhoto]);

  const clearScreenshot = useCallback(() => {
    trackRetake();

    setPhoto({ src: null, takenAt: null, submitted: false, skipped: false });
  }, [setPhoto, trackRetake]);

  const handleMedia = () => {
    setIsLoading(false);
  };

  const handleError = (e) => {
    const text = e.code === 0 ? `${t('photo_capture.error_access')}` : `${t('photo_capture.error_not_supported')}`;
    setErrorText(text);
    setIsLoading(false);
    setCameraDisabled(true);
  };

  const webcam = (
    <div>
      {isLoading && <Loader className="photo-capture__webcam-loader" />}
      {errorText && <div className="photo-capture__webcam-error">{errorText}</div>}
      <Webcam
        className="photo-capture__webcam"
        id="webcam"
        ref={webcamRef}
        audio={false}
        width={540}
        height={720}
        screenshotFormat="image/jpeg"
        videoConstraints={videoConstraints}
        onUserMedia={handleMedia}
        onUserMediaError={handleError}
      />
      {!errorText && (
        <button className="ray-link photo-capture__webcam-button" type="button" onClick={captureScreenshot}>
          <img src={shutter} alt={t('photo_capture.alt_text_shutter_button')} />
        </button>
      )}
    </div>
  );
  const screenshot = (
    <>
      <ClearScreenshot className="photo-capture__clear-screenshot" onClick={clearScreenshot} />
      <div className="photo-capture__screenshot">
        <img src={photoSrc} alt={t('photo_capture.alt_text_camera_screenshot')} />
      </div>
    </>
  );

  return (
    <div className="photo-capture">
      <div className="photo-capture__camera">{photoSrc ? screenshot : webcam}</div>
    </div>
  );
};

PhotoCapture.propTypes = {
  photoSrc: PropTypes.string,
  setPhoto: PropTypes.func,
  setCameraDisabled: PropTypes.func,
  trackTakePhoto: PropTypes.func,
  trackRetake: PropTypes.func,
};

export default PhotoCapture;
