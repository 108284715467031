import { CHECK_IN_SIGN_IN_PATH } from 'api/constants';
import { getCheckInCredentials, getOptions } from 'utils/api';

export const getCheckInApiConfig = (id, queryParams, body) => {
  const credentials = getCheckInCredentials(id, queryParams);
  const options = getOptions('POST', credentials, JSON.stringify(body));

  return {
    path: CHECK_IN_SIGN_IN_PATH,
    options,
  };
};
