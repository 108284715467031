import { useEffect } from 'react';
import { navigate } from 'hookrouter';

export const useCheckInNavigation = (
  settings,
  isVisitorFormComplete,
  isPhotoComplete,
  isCovidWaiverComplete,
  checkedIn,
  queryParams,
) => {
  useEffect(() => {
    if (!settings) {
      return;
    }
    if (checkedIn) {
      navigate('/confirmation', true, queryParams);
    } else if (isVisitorFormComplete) {
      if (isPhotoComplete && isCovidWaiverComplete) {
        navigate('/host', true, queryParams);
      } else if (isPhotoComplete) {
        navigate('/agreement', true, queryParams);
      } else {
        navigate('/photo', true, queryParams);
      }
    } else {
      navigate('/', true, queryParams);
    }
  }, [settings, isVisitorFormComplete, isPhotoComplete, isCovidWaiverComplete, checkedIn, queryParams]);
};
