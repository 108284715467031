import arAR from 'locales/ar-AR.json';
import csCZ from 'locales/cs-CZ.json';
import deDE from 'locales/de-DE.json';
import enUS from 'locales/en-US.json';
import esES from 'locales/es-ES.json';
import esLA from 'locales/es-LA.json';
import frCA from 'locales/fr-CA.json';
import frFR from 'locales/fr-FR.json';
import idID from 'locales/id-ID.json';
import itIT from 'locales/it-IT.json';
import jaJP from 'locales/ja-JP.json';
import koKR from 'locales/ko-KR.json';
import nlNL from 'locales/nl-NL.json';
import plPL from 'locales/pl-PL.json';
import ptBR from 'locales/pt-BR.json';
import ruRU from 'locales/ru-RU.json';
import thTH from 'locales/th-TH.json';
import viVN from 'locales/vi-VN.json';
import zhHans from 'locales/zh-Hans.json';
import zhHant from 'locales/zh-Hant.json';

export const TRANSLATIONS = {
  'ar-AR': arAR,
  'cs-CZ': csCZ,
  'de-DE': deDE,
  'en-US': enUS,
  'es-ES': esES,
  'es-LA': esLA,
  'fr-CA': frCA,
  'fr-FR': frFR,
  'id-ID': idID,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-BR': ptBR,
  'ru-RU': ruRU,
  'th-TH': thTH,
  'vi-VN': viVN,
  'zh-Hans': zhHans,
  'zh-Hant': zhHant,
};
