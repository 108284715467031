export const getPathArr = () => {
  return window.location.pathname.split('/');
};

export const parseRegistrationType = () => {
  return getPathArr()[1];
};

export const parseIdFromUrl = () => {
  return getPathArr()[2];
};
