import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { useEffect } from 'react';

import { getTranslation } from 'utils/localization';

const defaultLocale = 'en-US';

const translate = (locale) => {
  const resources = {
    [locale]: {
      translation: getTranslation(locale),
    },
  };

  i18n.use(initReactI18next).init({
    resources,
    lng: locale,
    fallbackLng: locale,
  });
};

export const useLocalization = (locale = defaultLocale) => {
  useEffect(() => {
    if (locale) {
      translate(locale);
    }
  }, [locale]);
};
