import React from 'react';
import Agreement from 'components/shared/Agreement/Agreement';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { useTrackViewCheckIn } from 'hooks/useTrackView';
import { trackInteraction } from 'services/analytics';

const CheckInCovidWaiver = (props) => {
  const {
    defaultAgreements: [{ name, content }],
    setIsCovidWaiverPassed,
    id,
    deviceId,
    registrationId,
  } = props;
  const { t } = useTranslation();

  const registrationType = 'check-in';
  const ctaText = t('accept');

  useTrackViewCheckIn('covid_waiver', id, deviceId, registrationId);

  const onSubmit = () => {
    const objectDetails = {
      location_id: id,
      device_id: deviceId,
      is_guest_pre_checked: false,
      registration_id: registrationId,
      object_identifier: ctaText,
    };
    trackInteraction('covid_waiver', objectDetails);

    setIsCovidWaiverPassed(true);
  };

  const variables = {
    registrationType,
    name,
    content,
    ctaText,
    onSubmit,
  };

  return <Agreement {...variables} />;
};

CheckInCovidWaiver.propTypes = {
  defaultAgreements: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
      content: PropTypes.string,
    }),
  ),
  setIsCovidWaiverPassed: PropTypes.func,
  id: PropTypes.string,
  deviceId: PropTypes.string,
  registrationId: PropTypes.string,
};

export default CheckInCovidWaiver;
