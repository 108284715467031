import React from 'react';

import ErrorBoundary from 'components/common/ErrorBoundary/ErrorBoundary';
import PageWrapper from 'components/common/PageWrapper/PageWrapper';
import Presign from 'components/presign/Presign';
import CheckIn from 'components/checkIn/CheckIn';
import { parseIdFromUrl, parseRegistrationType } from 'utils/url';
import { setBasepath } from 'hookrouter';

const registrationType = parseRegistrationType();
const id = parseIdFromUrl();

setBasepath(`/${registrationType}/${id}`);

const App = () => {
  return (
    <ErrorBoundary>
      <PageWrapper>{registrationType === 'presign' ? <Presign /> : <CheckIn />}</PageWrapper>
    </ErrorBoundary>
  );
};

export default App;
