import { useSendData } from 'api/useApi';

export const parseRegistration = (data) => {
  if (!data || !data.registration) {
    return null;
  }
  const {
    registration,
    registration: { id, host, signatures },
  } = data;
  return {
    id,
    firstName: registration.first_name,
    lastName: registration.last_name,
    expectedAt: registration.expected_at,
    host: {
      firstName: host.first_name,
      lastName: host.last_name,
    },
    hostId: registration.host_id,
    signatures,
  };
};

export const parseVisitor = (data) => {
  if (!data || !data.visitor) {
    return null;
  }
  const takePhoto = data.visitor.take_photo;
  const photoExpiresAt = data.visitor.photo_expires_at;
  const photoTakenAt = data.visitor.photo_taken_at;
  return { takePhoto, photoExpiresAt, photoTakenAt };
};

export const useLookupApi = () => {
  const { response, error, isSending, callApi } = useSendData();

  return { lookupResponse: response, lookupError: error, lookingUp: isSending, callLookupApi: callApi };
};
