import React from 'react';
import Header from 'components/common/Header/Header';
import 'components/common/PageWrapper/PageWrapper.scss';

const PageWrapper = ({ children }) => {
  return (
    <div className="ray-page-container">
      <Header />
      {children}
    </div>
  );
};

export default PageWrapper;
