import React from 'react';

import { ReactComponent as WeWorkLogo } from 'assets/WeWorkLogo.svg';
import 'components/common/Header/Header.scss';

const Header = () => {
  return (
    <header className="contactless-signin__header hide-phone">
      <WeWorkLogo />
    </header>
  );
};

export default Header;
