import { useSendData } from 'api/useApi';
import { useEffect } from 'react';
import { getPhotoApiConfig } from 'api/config/presign/getPhotoApiConfig';

export const usePhotoApi = (registrationId, queryParams, uploadedPhoto, photoTakenAt) => {
  const { response, isSending, callApi, error } = useSendData();

  useEffect(() => {
    if (uploadedPhoto) {
      const { path, signature } = uploadedPhoto;
      const photo = {
        photo_taken_at: photoTakenAt,
        photo: {
          path,
          signature,
        },
      };
      const { path: apiPath, options } = getPhotoApiConfig(registrationId, queryParams, photo);
      callApi({ path: apiPath, options });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [registrationId, queryParams, photoTakenAt, uploadedPhoto]);

  return { photoResponse: response, sendingPhoto: isSending, photoError: error };
};
