import React, { useState } from 'react';
import { connectAutoComplete, connectStateResults } from 'react-instantsearch-dom';
import AutoSuggest from 'react-autosuggest';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import CtaButton from 'components/common/CtaButton/CtaButton';
import { ReactComponent as Loader } from 'assets/loader.svg';
import { ReactComponent as SearchIcon } from './assets/search.svg';
import { ReactComponent as CloseIcon } from './assets/close.svg';

const AutoCompleteComponent = (props) => {
  const {
    hits,
    refine,
    currentRefinement,
    isSearchStalled,
    hostsAllowSkip,
    setHost,
    trackHostSubmit,
    trackHostSkip,
  } = props;

  const { t } = useTranslation();
  const [currentValue, setValue] = useState(currentRefinement);
  const [isNextShown, showNext] = useState(false);
  const [selectedHost, setSelectedHost] = useState('');
  const [isSkipButtonShown, showSkipButton] = useState(false);

  const onChange = (event, { newValue }) => {
    setValue(newValue);
  };

  const onSuggestionsFetchRequested = ({ value }) => {
    const valueLength = value?.trim().length;
    const hitsLength = hits?.length;
    const shouldShowSkipButton =
      (hostsAllowSkip && valueLength > 0) || (hostsAllowSkip && valueLength >= 8 && hitsLength === 0);
    showSkipButton(shouldShowSkipButton);
    refine(value);
  };

  const skipHost = () => {
    trackHostSkip();
    setHost({ skipped: true });
  };

  const onSuggestionSelected = () => {
    showNext(true);
    refine();
  };

  const handleNextClick = () => {
    trackHostSubmit();
    setHost(selectedHost);
  };

  const handleDelete = () => {
    setValue('');
    showSkipButton(false);
    showNext(false);
    refine();
  };

  const getSuggestionValue = (hit) => {
    setSelectedHost(hit);
    return hit.full_name;
  };

  const renderSuggestion = (hit) => {
    return (
      <>
        <p>{hit.full_name}</p>
        <p className="host-lookup__company">{hit.company}</p>
      </>
    );
  };

  const renderSkipButton = () => (
    <button onClick={skipHost} className="host-lookup__no-result ray-text--body" type="button">
      {t('host_lookup.not_found')}
    </button>
  );

  const onKeyDown = (e) => {
    if (e.keyCode === 8) {
      showNext(false);
    }
  };

  const customInputProps = {
    placeholder: t('host_lookup.input_placeholder'),
    onChange,
    onKeyDown,
    value: currentValue,
    className: 'host-lookup__input ray-text-field__input',
    autoFocus: true,
  };

  const renderInputComponent = (inputProps) => (
    <div className="ray-grid__cell--span-full ray-grid__cell--span-4-tablet ray-grid__cell--span-6-desktop">
      <div className="ray-text-field">
        <SearchIcon />
        <input {...inputProps} />
        {currentValue && <CloseIcon onClick={handleDelete} />}
      </div>
      {isNextShown && (
        <div className="host-lookup__next-wrapper">
          <CtaButton onClick={handleNextClick} value={t('next')} />
        </div>
      )}
    </div>
  );

  const searchResult = (suggestionsProps) =>
    !isNextShown && (
      <>
        <div {...suggestionsProps.containerProps}>{suggestionsProps.children}</div>
        {isSkipButtonShown && renderSkipButton()}
      </>
    );

  const renderSuggestionsContainer = (suggestionsProps) => (
    <div className="host-lookup__list ray-grid__cell--span-full ray-grid__cell--span-4-tablet ray-grid__cell--span-6-desktop">
      {isSearchStalled ? (
        <div className="search-loader-container">
          <Loader className="search-loader" />
        </div>
      ) : (
        searchResult(suggestionsProps)
      )}
    </div>
  );

  return (
    <AutoSuggest
      suggestions={hits}
      onSuggestionsFetchRequested={onSuggestionsFetchRequested}
      getSuggestionValue={getSuggestionValue}
      renderSuggestion={renderSuggestion}
      inputProps={customInputProps}
      renderInputComponent={renderInputComponent}
      renderSuggestionsContainer={renderSuggestionsContainer}
      onSuggestionSelected={onSuggestionSelected}
      alwaysRenderSuggestions
    />
  );
};

AutoCompleteComponent.propTypes = {
  hits: PropTypes.arrayOf(
    PropTypes.shape({
      full_name: PropTypes.string,
      company: PropTypes.string,
    }),
  ),
  currentRefinement: PropTypes.string,
  refine: PropTypes.func,
  isSearchStalled: PropTypes.bool,
  hostsAllowSkip: PropTypes.bool,
  setHost: PropTypes.func,
  trackHostSubmit: PropTypes.func,
  trackHostSkip: PropTypes.func,
};

const AutoComplete = connectAutoComplete(connectStateResults(AutoCompleteComponent));

export default AutoComplete;
