import { useCallback, useEffect, useState } from 'react';
import { mock, useMock } from 'api/mocks/mock';

const parseJSON = async (response) => {
  if (!response.ok) {
    throw Error(`${response.status}, ${response.statusText}`);
  }
  if (response.status === 204) {
    return {};
  }
  return response.json();
};

export const useSetupData = (path, options) => {
  const [data, setData] = useState(null);
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchData = async () => {
      try {
        if (useMock) {
          const res = await mock(path);
          setData(res.data);
        } else {
          const res = await fetch(path, options);
          const json = await parseJSON(res);
          setData(json.data);
        }
      } catch (e) {
        setError(e);
      }
      setIsLoading(false);
    };

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, error, isLoading };
};

export const useSendData = () => {
  const [isSending, setIsSending] = useState(false);
  const [response, setResponse] = useState(null);
  const [error, setError] = useState(null);

  const callApi = useCallback(
    ({ path, options }) => {
      if (isSending || !path) return;

      const sendData = async () => {
        setIsSending(true);
        try {
          if (useMock) {
            setResponse(await mock(path));
          } else {
            const res = await fetch(path, options);
            const json = await parseJSON(res);
            setResponse(json);
          }
        } catch (e) {
          setError(e);
        }
        setIsSending(false);
      };

      sendData();
    },
    [isSending, setIsSending, setResponse, setError],
  );

  return { response, error, isSending, callApi };
};
