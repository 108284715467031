import React from 'react';
import NotFound from 'components/common/NotFound/NotFound';
import PageWrapper from 'components/common/PageWrapper/PageWrapper';

export default class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  render() {
    const { hasError } = this.state;
    const { children } = this.props;
    if (hasError) {
      return (
        <PageWrapper>
          <NotFound />
        </PageWrapper>
      );
    }
    return children;
  }
}
