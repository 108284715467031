import { useSendData } from 'api/useApi';
import { getPhotoUploadApiConfig } from 'api/config/getPhotoUploadApiConfig';
import { useEffect } from 'react';

export const usePhotoUploadApi = (allStepsPassed, { src, submitted }) => {
  const { response, isSending, callApi, error } = useSendData();
  const uploadedPhotoResponse = response ? response.data : null;
  useEffect(() => {
    if (allStepsPassed && src && submitted) {
      const config = getPhotoUploadApiConfig(src);
      callApi(config);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [allStepsPassed, src, submitted]);

  return { uploadedPhotoResponse, uploadingPhoto: isSending, uploadedPhotoError: error };
};
