import React from 'react';
import PropTypes from 'prop-types';

import { getSteps, getStepWidth } from 'components/common/ProgressBar/ProgressBarConfig';
import 'components/common/ProgressBar/ProgressBar.scss';

const ProgressBar = (props) => {
  const { registrationType, stepName } = props;
  const steps = getSteps(registrationType);
  const stepWidth = getStepWidth(registrationType);

  return (
    <div className="progress-bar hide-tablet">
      {steps.map((step, i) => {
        const completed = i < steps.indexOf(stepName);
        const isActive = step === stepName;

        return (
          <div
            className={`progress-bar__step${completed || isActive ? '' : ' progress-bar__step--disabled'}`}
            style={{ width: `${stepWidth}%` }}
            key={step}
          />
        );
      })}
    </div>
  );
};

ProgressBar.propTypes = {
  registrationType: PropTypes.string,
  stepName: PropTypes.string,
};

export default ProgressBar;
