export const getObjectDetails = ({ registrationId, locationId, deviceId, isGuestPreChecked, referrer, visitorId }) => {
  return {
    registration_id: registrationId,
    location_id: locationId,
    device_id: deviceId,
    is_guest_pre_checked: isGuestPreChecked,
    ...(referrer && { referrer }),
    ...(visitorId && { vistor_id: visitorId }),
  };
};
