import React, { useState } from 'react';
import PropTypes from 'prop-types';
import algoliasearch from 'algoliasearch';
import { InstantSearch, Configure } from 'react-instantsearch-dom';
import { useTranslation } from 'react-i18next';

import ProgressBar from 'components/common/ProgressBar/ProgressBar';
import { useTrackViewCheckIn } from 'hooks/useTrackView';
import { trackInteraction } from 'services/analytics';
import AutoComplete from './AutoComplete';
import './HostLookup.scss';

const HostLookup = (props) => {
  const { id, locationUserSearchKey, hostsAllowSkip, setHost, deviceId, registrationId } = props;
  const { t } = useTranslation();
  const [prefixType, setPrefixType] = useState('prefixLast');

  useTrackViewCheckIn('host_form', id, deviceId, registrationId);

  const commonDetails = {
    registration_id: registrationId,
    location_id: id,
    device_id: deviceId,
    is_guest_pre_checked: false,
  };

  const trackHostSubmit = () => {
    const objectDetails = {
      ...commonDetails,
      object_identifier: 'hostName',
    };
    trackInteraction('host_form', objectDetails);
  };

  const trackHostSkip = () => {
    const objectDetails = {
      ...commonDetails,
      object_identifier: 'hostSkip',
    };
    trackInteraction('host_form', objectDetails);
  };

  const algoliaClient = algoliasearch(process.env.REACT_APP_ALGOLIA_APP_ID, locationUserSearchKey);
  const searchClient = {
    search(requests) {
      if (requests.every(({ params }) => !params.query)) {
        return Promise.resolve({
          results: requests.map(() => ({
            hits: [],
            nbHits: 0,
            nbPages: 0,
            processingTimeMS: 0,
          })),
        });
      }
      if (requests.every(({ params }) => params.query.trim().length && params.query.trim().length < 8)) {
        setPrefixType('prefixNone');
      } else {
        setPrefixType('prefixLast');
      }

      return algoliaClient.search(requests);
    },
  };

  return (
    <div className="host-lookup main-section">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-full ray-grid__cell--span-8-desktop">
          <h1>
            <span className="ray-text--h1">{t('host_lookup.title')}</span>
          </h1>
          <ProgressBar stepName="HostLookup" registrationType="check-in" />
          <div className="ray-grid">
            <InstantSearch searchClient={searchClient} indexName="users">
              <Configure queryType={prefixType} />
              <AutoComplete
                hostsAllowSkip={hostsAllowSkip}
                setHost={setHost}
                trackHostSubmit={trackHostSubmit}
                trackHostSkip={trackHostSkip}
              />
            </InstantSearch>
          </div>
        </div>
      </div>
    </div>
  );
};

HostLookup.propTypes = {
  id: PropTypes.string,
  locationUserSearchKey: PropTypes.string,
  hostsAllowSkip: PropTypes.bool,
  setHost: PropTypes.func,
  deviceId: PropTypes.string,
  registrationId: PropTypes.string,
};

export default HostLookup;
