import React from 'react';
import { useQueryParams } from 'hookrouter';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Agreement from 'components/shared/Agreement/Agreement';
import { getSignatureApiConfig } from 'api/config/presign/getSignatureApiConfig';
import { useTrackViewPresign } from 'hooks/useTrackView';
import { trackInteraction } from 'services/analytics';

const PresignCovidWaiver = (props) => {
  const {
    id,
    location: {
      id: locationId,
      agreements: [{ id: agreementId, name, content }],
    },
    setIsCovidWaiverPassed,
    sendingData,
    callSignatureApi,
  } = props;
  const { t } = useTranslation();
  const [queryParams] = useQueryParams();
  const ctaText = t('accept');

  useTrackViewPresign('covid_waiver', id, locationId);

  const onSubmit = () => {
    setIsCovidWaiverPassed(true);
    const config = getSignatureApiConfig(id, queryParams, agreementId);
    callSignatureApi(config);
    const objectDetails = {
      registration_id: id,
      location_id: locationId,
      object_identifier: ctaText,
      is_guest_pre_checked: true,
    };
    trackInteraction('covid_waiver', objectDetails);
  };
  const variables = {
    name,
    content,
    ctaText,
    registrationType: 'presign',
    isSubmitting: sendingData,
    onSubmit,
  };

  return <Agreement {...variables} />;
};

PresignCovidWaiver.propTypes = {
  id: PropTypes.string.isRequired,
  location: PropTypes.shape({
    id: PropTypes.string,
    agreements: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        name: PropTypes.string,
        content: PropTypes.string,
      }),
    ),
  }),
  setIsCovidWaiverPassed: PropTypes.func,
  sendingData: PropTypes.bool,
  callSignatureApi: PropTypes.func,
};

export default PresignCovidWaiver;
