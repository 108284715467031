import { useSetupData } from 'api/useApi';
import { getVisitorApiConfig } from 'api/config/presign/getVisitorApiConfig';

export const parseRegistration = (data) => {
  if (!data || !data.registration) {
    return null;
  }
  const {
    registration,
    registration: { id, location, host, signatures },
  } = data;
  return {
    id,
    expectedAt: registration.expected_at,
    firstName: registration.first_name,
    lastName: registration.last_name,
    photoRequired: location.photo_required,
    location: {
      id: location.id,
      displayName: location.display_name,
      photoUrl: location.photo_url,
      photoEnabled: location.photo_enabled,
      rememberPhoto: location.remember_photo,
      agreements: location.agreements,
      agreementsEnabled: location.agreements_enabled,
      locale: location.locale,
      timezone: location.timezone,
      landingFloorEnabled: location.landing_floor_enabled,
      landingAreaFloor: location.landing_area_floor,
      address: location.address,
      qrEnabled: location.qr_enabled,
    },
    host: {
      firstName: host.first_name,
      lastName: host.last_name,
    },
    signatures,
  };
};

export const parseVisitor = (data) => {
  if (!data || !data.visitor) {
    return null;
  }
  const takePhoto = data.visitor.take_photo;
  const photoExpiresAt = data.visitor.photo_expires_at;
  return { takePhoto, photoExpiresAt };
};

export const useVisitorApi = (registrationId, queryParams) => {
  const { path, options } = getVisitorApiConfig(registrationId, queryParams);
  const { data, error, isLoading } = useSetupData(path, options);

  return { setupData: data, setupError: error, isSetupLoading: isLoading };
};
