import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ReactComponent as WeWorkLogo } from 'assets/WeWorkLogo.svg';
import { formatCheckInDate, getFormattedUTCTime } from 'utils/date';
import 'components/checkIn/CheckInConfirmation.scss';

const CheckInConfirmationCard = (props) => {
  const { locale, timezone } = props;
  const { t } = useTranslation();

  const second = 'numeric';
  const rawDate = new Date();
  const date = formatCheckInDate(rawDate, locale, timezone);
  const time = getFormattedUTCTime(rawDate, locale, timezone, second);

  return (
    <div className="confirmation-card">
      <p className="confirmation-card__logo">
        <WeWorkLogo />
      </p>
      <p className="ray-text--monospace confirmation-card__mono">{t('checkin_confirmation.checked_in')}</p>
      <p className="ray-text--h1 confirmation-card__date">{date}</p>
      <p className="ray-text--body-large confirmation-card__time">{time}</p>
    </div>
  );
};

CheckInConfirmationCard.propTypes = {
  locale: PropTypes.string,
  timezone: PropTypes.string,
};

export default CheckInConfirmationCard;
