import { trackInteraction } from 'services/analytics';
import { useTrackViewPresign } from 'hooks/useTrackView';
import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { prepareVisitDetails } from 'components/shared/VisitDetails/VisitDetails';
import VisitorForm from 'components/shared/VisitForm/VisitorForm';

const PresignVisitorForm = (props) => {
  const {
    id,
    firstName,
    lastName,
    location: { id: locationId, displayName, photoUrl },
    setIsVisitorFormPassed,
  } = props;
  const { t } = useTranslation();
  const registrationType = 'presign';
  const ctaText = t('next');

  const onSubmit = () => {
    const objectDetails = {
      registration_id: id,
      location_id: locationId,
      object_identifier: ctaText,
      is_guest_pre_checked: true,
    };
    trackInteraction('visitor_form', objectDetails);

    setIsVisitorFormPassed(true);
  };

  useTrackViewPresign('visitor_form', id, locationId);

  const variables = {
    displayName,
    photoUrl,
    registrationType,
    firstName,
    lastName,
    ctaText,
    visitDetails: prepareVisitDetails(props),
    onSubmit,
  };

  return <VisitorForm {...variables} />;
};

PresignVisitorForm.propTypes = {
  id: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  location: PropTypes.shape({
    id: PropTypes.string,
    displayName: PropTypes.string,
    photoUrl: PropTypes.string,
  }),
  setIsVisitorFormPassed: PropTypes.func,
};

export default PresignVisitorForm;
