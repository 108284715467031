import { useSendData } from 'api/useApi';
import { useEffect } from 'react';
import { getCheckInApiConfig } from 'api/config/checkIn/getCheckInApiConfig';

export const useCheckInApi = (
  shouldUseCheckIn,
  uploadedPhotoResponse,
  deviceId,
  queryParams,
  settings,
  registration,
  visitorName,
  photo,
  isCovidWaiverPassed,
  host,
) => {
  const { response, error, isSending, callApi } = useSendData();

  useEffect(() => {
    if (shouldUseCheckIn) {
      const body = {
        registration_id: registration?.id,
        first_name: visitorName.firstName,
        last_name: visitorName.lastName,
        signed_in_at: new Date().toISOString(),
        ...(uploadedPhotoResponse && {
          photo: {
            taken_at: photo?.takenAt,
            image: {
              path: uploadedPhotoResponse?.path,
              signature: uploadedPhotoResponse?.signature,
            },
          },
        }),
        ...(isCovidWaiverPassed && {
          signatures: [
            {
              agreement_id: settings.defaultAgreements[0].id,
              signed_at: new Date().toISOString(),
            },
          ],
        }),
        host_id: host?.objectID || registration?.hostId,
      };
      const { path: apiPath, options } = getCheckInApiConfig(deviceId, queryParams, body);
      callApi({ path: apiPath, options });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [shouldUseCheckIn]);

  return { checkedIn: Boolean(response), checkInError: error, checkingIn: isSending };
};
