import React from 'react';
import PropTypes from 'prop-types';
import ReactMarkdown from 'react-markdown';
import { useTranslation } from 'react-i18next';
import { ReactComponent as Loader } from 'assets/loader.svg';
import ProgressBar from 'components/common/ProgressBar/ProgressBar';

import 'components/shared/Agreement/Agreement.scss';
import { useScrollTop } from 'hooks/useScrollTop';

const Agreement = (props) => {
  const { name, registrationType, content, isSubmitting, ctaText, onSubmit } = props;
  const { t } = useTranslation();
  useScrollTop();

  return (
    <div className="agreement main-section">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-8-desktop ray-grid__cell--span-8-tablet ray-grid__cell--span-6-phone">
          <h1>
            <span className="ray-text--h1">{t('agreement.title', { name })}</span>
          </h1>
          <ProgressBar stepName="Agreement" registrationType={registrationType} />
          <ReactMarkdown source={content} className="ray-running-text agreement__body-text" />
        </div>
      </div>
      <button
        disabled={isSubmitting}
        type="button"
        className="ray-button ray-button--primary button-loader-container"
        onClick={onSubmit}
      >
        {isSubmitting && <Loader className="button-loader" />}
        <span {...(isSubmitting && { style: { visibility: 'hidden' } })}>{ctaText}</span>
      </button>
    </div>
  );
};

Agreement.propTypes = {
  name: PropTypes.string,
  registrationType: PropTypes.string,
  content: PropTypes.string,
  isSubmitting: PropTypes.bool,
  ctaText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default Agreement;
