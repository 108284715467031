import React from 'react';
import { useTranslation } from 'react-i18next';

import 'components/common/NotFound/NotFound.scss';

const NotFound = () => {
  const { t } = useTranslation();
  return (
    <div className="not-found main-section">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-full ray-grid__cell--span-8-desktop">
          <h1>
            <span className="ray-text--h1">{t('not_found.title')}</span>
          </h1>
          <div className="ray-text--body">{t('not_found.description')}</div>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
