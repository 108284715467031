import { PRESIGN_PHOTOS_PATH } from 'api/constants';
import { getOptions, getPresignCredentials } from 'utils/api';

export const getPhotoApiConfig = (id, queryParams, photo) => {
  const credentials = getPresignCredentials(id, queryParams);
  const options = getOptions('POST', credentials, JSON.stringify(photo));

  return {
    path: PRESIGN_PHOTOS_PATH,
    options,
  };
};
