import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation, Trans } from 'react-i18next';
import CheckInConfirmationCard from 'components/checkIn/CheckInConfirmationCard';
import 'components/checkIn/CheckInConfirmation.scss';
import { useTrackViewCheckIn } from 'hooks/useTrackView';
import { formatEnToOrdinal } from 'utils/num';

const CheckInConfirmation = (props) => {
  const {
    id,
    visitorName,
    host,
    isHQ,
    visitorLandingFloor,
    printerEnabled,
    visitorId,
    deviceId,
    registrationId,
    locale,
    timezone,
  } = props;
  const { t } = useTranslation();
  const floor = Number.isNaN(+visitorLandingFloor)
    ? visitorLandingFloor
    : formatEnToOrdinal(+visitorLandingFloor, locale);
  useTrackViewCheckIn('thanks', id, deviceId, registrationId, visitorId);
  return (
    <div className="check-in-confirmation main-section">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-full ray-grid__cell--span-8-desktop">
          <h1 className="check-in-confirmation__title">
            <span className="ray-text--h1">
              {visitorName.firstName
                ? t('checkin_confirmation.greeting_by_name', { visitorFirstName: visitorName.firstName })
                : t('checkin_confirmation.greeting_general')}
            </span>
          </h1>
          <p className="ray-text--body-large check-in-confirmation__text">{t('checkin_confirmation.description')}</p>
          <CheckInConfirmationCard host={host} locale={locale} timezone={timezone} />
          <div className="check-in-confirmation__separator" />
          <p className="ray-text--body-large check-in-confirmation__text">
            {!host && t('checkin_confirmation.host_alert_generic')}
            {host && host.first_name && (
              <Trans i18nKey="checkin_confirmation.host_alert_by_name">
                <strong>{{ hostFirstName: host.first_name }}</strong>
              </Trans>
            )}
            {host && !host.first_name && t('checkin_confirmation.host_alert_no_name')}
          </p>
          <p className="ray-text--body-large check-in-confirmation__text">
            {isHQ && visitorLandingFloor && t('checkin_confirmation.cta_hq_floor', { floor })}
            {isHQ && !visitorLandingFloor && t('checkin_confirmation.cta_hq_lobby')}
            {!isHQ && t('checkin_confirmation.cta_no_hq')}
          </p>
          <p className="ray-text--body-large check-in-confirmation__text">
            {printerEnabled && t('checkin_confirmation.printer_enabled')}
          </p>
        </div>
      </div>
    </div>
  );
};

CheckInConfirmation.propTypes = {
  id: PropTypes.string,
  visitorName: PropTypes.shape({
    firstName: PropTypes.string,
  }),
  host: PropTypes.shape({
    first_name: PropTypes.string,
    last_name: PropTypes.string,
    skipped: PropTypes.bool,
  }),
  isHQ: PropTypes.bool,
  visitorLandingFloor: PropTypes.string,
  printerEnabled: PropTypes.bool,
  visitorId: PropTypes.string,
  deviceId: PropTypes.string,
  registrationId: PropTypes.string,
  locale: PropTypes.string,
  timezone: PropTypes.string,
};

export default CheckInConfirmation;
