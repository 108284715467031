import { useSetupData } from 'api/useApi';
import { getSettingsApiConfig } from 'api/config/checkIn/getSettingsApiConfig';

export const parseSettings = (data) => {
  if (!data) {
    return null;
  }
  const { id, locale, timezone, address } = data;
  return {
    displayName: data.display_name,
    photoUrl: data.photo_url,
    photoRequired: data.photo_required,
    photoEnabled: data.photo_enabled,
    rememberPhoto: data.remember_photo,
    landingFloorEnabled: data.landing_floor_enabled,
    visitorLandingFloor: data.visitor_landing_floor,
    printerEnabled: data.printer_enabled,
    isHQ: data.is_hq,
    locationUserSearchKey: data.location_user_search_key,
    hostsEnabled: data.hosts_enabled,
    hostsAllowSkip: data.hosts_allow_skip,
    visitorFields: data.visitor_fields,
    agreementsEnabled: data.agreements_enabled,
    defaultAgreements: data.default_agreements,
    id,
    locale,
    timezone,
    address,
  };
};

export const useSettingsApi = (deviceId, queryParams) => {
  const { path, options } = getSettingsApiConfig(deviceId, queryParams);
  const { data, error, isLoading } = useSetupData(path, options);
  const settings = parseSettings(data);

  return { settings, setupError: error, isSetupLoading: isLoading };
};
