import { useEffect } from 'react';
import { getObjectDetails } from 'utils/analytics';
import { trackView } from '../services/analytics';

export const useTrackViewPresign = (sourceScreen, registrationId, locationId) => {
  useEffect(() => {
    if (locationId) {
      const objectDetails = {
        registrationId,
        locationId,
        is_guest_pre_checked: true,
      };

      trackView(sourceScreen, objectDetails);
    }
  }, [sourceScreen, registrationId, locationId]);
};

export const useTrackViewCheckIn = (
  sourceScreen,
  locationId,
  deviceId,
  registrationId = '',
  referrer = '',
  visitorId = '',
) => {
  useEffect(() => {
    if (locationId) {
      const variables = {
        locationId,
        deviceId,
        registrationId,
        referrer,
        visitorId,
        isGuestPreChecked: false,
      };
      const objectDetails = getObjectDetails(variables);

      trackView(sourceScreen, objectDetails);
    }
  }, [sourceScreen, locationId, deviceId, registrationId, referrer, visitorId]);
};
