const checkIsHostPrefilled = (registration) => {
  return registration?.host?.firstName && registration?.host?.lastName;
};

export const checkIsHostComplete = (host, settings, registration) => {
  const isHostPassed = Boolean(host);
  const isHostEnabled = settings?.hostsEnabled;
  const isHostPrefilled = checkIsHostPrefilled(registration);
  return !isHostEnabled || isHostPrefilled || isHostPassed;
};
