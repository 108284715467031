import {
  CHECK_IN_LOOKUP_PATH,
  CHECK_IN_SETTINGS_PATH,
  CHECK_IN_SIGN_IN_PATH,
  PHOTO_UPLOAD_URL,
  PRESIGN_PHOTOS_PATH,
  PRESIGN_SIGNATURES_PATH,
  PRESIGN_VISITOR_PATH,
} from 'api/constants';

export const useMock = process.env.REACT_APP_USE_MOCK;

export const mock = (path) => {
  switch (path) {
    case PRESIGN_VISITOR_PATH:
      return import('api/mocks/presign/visitor');
    case PRESIGN_SIGNATURES_PATH:
      return import('api/mocks/presign/signatures');
    case PRESIGN_PHOTOS_PATH:
      return import('api/mocks/presign/photos');
    case CHECK_IN_SETTINGS_PATH:
      return import('api/mocks/checkIn/settings');
    case CHECK_IN_LOOKUP_PATH:
      return import('api/mocks/checkIn/lookup');
    case CHECK_IN_SIGN_IN_PATH:
      return import('api/mocks/checkIn/signIn');
    case PHOTO_UPLOAD_URL:
      return import('api/mocks/photoUpload');
    default:
      return {};
  }
};
