export const getFormattedUTCDate = (date, locale, options) => {
  const dateFormatter = new Intl.DateTimeFormat(locale, options);

  return dateFormatter.format(date);
};

export const formatVisitDate = (date, locale, timeZone) => {
  const options = {
    weekday: 'long',
    day: 'numeric',
    month: 'long',
    year: 'numeric',
    timeZone,
  };

  return getFormattedUTCDate(date, locale, options);
};

export const formatCheckInDate = (date, locale, timeZone) => {
  const options = {
    day: 'numeric',
    month: 'short',
    year: 'numeric',
    timeZone,
  };

  return getFormattedUTCDate(date, locale, options);
};

export const getFormattedUTCTime = (date, locale, timeZone, second) => {
  const timeFormatter = new Intl.DateTimeFormat(locale, {
    hour: 'numeric',
    minute: 'numeric',
    second,
    timeZone,
  });

  return timeFormatter.format(date);
};

const getBeginningOfNextDay = (date) => new Date(date.getFullYear(), date.getMonth(), date.getDate() + 1, 0, 0, 0, 0);

export const checkIsDateValid = (date) => {
  const registrationDate = new Date(date);
  const currentDate = new Date();
  const registrationNextDay = getBeginningOfNextDay(registrationDate);
  const currentNextDay = getBeginningOfNextDay(currentDate);

  return registrationNextDay.getTime() >= currentNextDay.getTime();
};
