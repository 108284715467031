import React from 'react';
import QRCode from 'qrcode.react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import VisitDetails from 'components/shared/VisitDetails/VisitDetails';
import 'components/presign/PresignConfirmation.scss';
import { useTrackViewPresign } from 'hooks/useTrackView';

const getQrCodeProps = (value) => ({
  value: `W.${value.toUpperCase()}`,
  level: 'M',
  size: 188,
  renderAs: 'svg',
});

const PresignConfirmation = (props) => {
  const { id, locationId, qrEnabled, visitDetails } = props;
  const { t } = useTranslation();

  useTrackViewPresign('pre_check_confirmation', id, locationId);

  return (
    <div className="presign-confirmation main-section">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-full ray-grid__cell--span-8-desktop">
          <h1>
            <span className="ray-text--h1">{t('presign_confirmation.title')}</span>
          </h1>
          <p className="ray-text--body-large">
            {qrEnabled && t('presign_confirmation.qr_description')}
            {t('presign_confirmation.closing')}
          </p>
          {qrEnabled && (
            <div className="presign-confirmation__qr-code">
              <QRCode {...getQrCodeProps(id)} />
            </div>
          )}
          <VisitDetails {...visitDetails} />
        </div>
      </div>
    </div>
  );
};

PresignConfirmation.propTypes = {
  id: PropTypes.string,
  locationId: PropTypes.string,
  qrEnabled: PropTypes.bool,
  visitDetails: PropTypes.shape(VisitDetails.propTypes),
};

export default PresignConfirmation;
