import React from 'react';
import { Trans, useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { useForm } from 'react-hook-form';
import { ReactComponent as Loader } from 'assets/loader.svg';
import ProgressBar from 'components/common/ProgressBar/ProgressBar';
import 'components/shared/VisitForm/VisitorForm.scss';
import VisitDetails from 'components/shared/VisitDetails/VisitDetails';

const WEWORK_PRIVACY_POLICY = 'https://www.wework.com/legal/global-privacy-policy';
const WELKIO_PRIVACY_POLICY = 'https://www.wework.com/legal/privacy-policy/welkio';

const VisitorForm = (props) => {
  const {
    displayName,
    photoUrl,
    registrationType,
    firstName,
    lastName,
    firstNameRequired,
    lastNameRequired,
    isSending,
    ctaText,
    visitDetails,
    onSubmit,
  } = props;
  const { t } = useTranslation();
  const { register, handleSubmit, errors, formState } = useForm({
    mode: 'onChange',
    defaultValues: {
      firstName,
      lastName,
    },
  });
  const validate = (value) => !!value.trim();
  const validationRules = { required: true, validate };
  return (
    <div className="visitor-form main-section">
      {displayName && (
        <h1>
          <span>{t('visitor_form.welcome')}</span>
          <br />
          <span>{displayName}</span>
        </h1>
      )}
      <div className="ray-grid">
        {photoUrl && (
          <div className="ray-grid__cell--span-full ray-grid__cell--span-4-tablet ray-grid__cell--span-6-desktop">
            <img
              className="ray-image visitor-form__field"
              src={photoUrl}
              alt={t('visitor_form.alt_text_office_image')}
            />
          </div>
        )}
        <div className="ray-grid__cell--span-full">
          <ProgressBar stepName="CheckIn" registrationType={registrationType} />
        </div>
        <div className="ray-grid__cell--span-full ray-grid__cell--span-6-desktop">
          <div className="ray-text--h4">{t('visitor_form.form_title')}</div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="ray-grid visitor-form__inputs">
              <div className="ray-grid__cell--span-full ray-grid__cell--span-4-tablet ray-grid__cell--span-6-desktop visitor-form__field">
                <div className={`ray-text-field ${errors.firstName ? 'ray-text-field--error' : ''}`}>
                  <input
                    disabled={firstName}
                    className="ray-text-field__input"
                    name="firstName"
                    ref={register(firstNameRequired && validationRules)}
                  />
                  <label className="ray-text-field__label" htmlFor="firstName">
                    {t('visitor_form.label_first_name')}
                    {firstNameRequired && '*'}
                  </label>
                </div>
                <div className="ray-form-item__hint ray-form-item__hint--error">
                  {errors.firstName && t('visitor_form.error_first_name')}
                </div>
              </div>
              <div className="ray-grid__cell--span-full ray-grid__cell--span-4-tablet ray-grid__cell--span-6-desktop visitor-form__field">
                <div className={`ray-text-field ${errors.lastName ? 'ray-text-field--error' : ''}`}>
                  <input
                    disabled={lastName}
                    className="ray-text-field__input"
                    name="lastName"
                    ref={register(lastNameRequired && validationRules)}
                  />
                  <label className="ray-text-field__label" htmlFor="lastName">
                    {t('visitor_form.label_last_name')}
                    {lastNameRequired && '*'}
                  </label>
                </div>
                <div className="ray-form-item__hint ray-form-item__hint--error">
                  {errors.lastName && t('visitor_form.error_last_name')}
                </div>
              </div>
            </div>
            <div className="visitor-form__field">
              <button
                disabled={!formState.isValid || isSending}
                className="ray-button ray-button--primary button-loader-container"
                type="submit"
              >
                {isSending && <Loader className="button-loader" />}
                <span {...(isSending && { style: { visibility: 'hidden' } })}>{ctaText}</span>
              </button>
            </div>
          </form>
          <div className="ray-text--body-x-small">
            <Trans i18nKey="visitor_form.privacy_policy">
              By entering your data and/or entering the premises, you acknowledge you have read and understood both the
              <a href={WEWORK_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                WeWork Privacy Policy
              </a>
              and the
              <a href={WELKIO_PRIVACY_POLICY} target="_blank" rel="noopener noreferrer">
                Welkio Privacy Policy
              </a>
              .
            </Trans>
          </div>
          {visitDetails && <VisitDetails {...visitDetails} />}
        </div>
      </div>
    </div>
  );
};

VisitorForm.propTypes = {
  displayName: PropTypes.string,
  photoUrl: PropTypes.string,
  registrationType: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  firstNameRequired: PropTypes.bool,
  lastNameRequired: PropTypes.bool,
  isSending: PropTypes.bool,
  visitDetails: PropTypes.shape(VisitDetails.propTypes),
  ctaText: PropTypes.string,
  onSubmit: PropTypes.func,
};

export default VisitorForm;
