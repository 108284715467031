import React, { useState } from 'react';
import { useQueryParams, useRoutes } from 'hookrouter';
import { useLocalization } from 'i18n';

import CheckInVisitorForm from 'components/checkIn/CheckInVisitorForm';
import CheckInCovidWaiver from 'components/checkIn/CheckInCovidWaiver';
import HostLookup from 'components/HostLookup/HostLookup';
import CheckInConfirmation from 'components/checkIn/CheckInConfirmation';
import CheckInPhoto from 'components/checkIn/CheckInPhoto';
import NotFound from 'components/common/NotFound/NotFound';
import { ReactComponent as Loader } from 'assets/loader.svg';
import { usePhotoUploadApi } from 'hooks/usePhotoUploadApi';
import { useCheckInApi } from 'hooks/checkIn/useCheckInApi';
import { useCheckInNavigation } from 'hooks/checkIn/useCheckInNavigation';
import { parseIdFromUrl } from 'utils/url';
import { checkIsCheckInPhotoComplete } from 'utils/photo';
import { useSettingsApi } from 'hooks/checkIn/useSettingsApi';
import { parseRegistration, parseVisitor, useLookupApi } from 'hooks/checkIn/useLookupApi';
import { checkIsCovidWaiverSigned, checkIsAgreementsStepSkipped } from 'utils/agreement';
import { checkIsHostComplete } from 'utils/host';

const routes = {
  '/': () => (props) => <CheckInVisitorForm {...props} />,
  '/photo': () => (props) => <CheckInPhoto {...props} />,
  '/agreement': () => (props) => <CheckInCovidWaiver {...props} />,
  '/host': () => (props) => <HostLookup {...props} />,
  '/confirmation': () => (props) => <CheckInConfirmation {...props} />,
};

const CheckIn = () => {
  const deviceId = parseIdFromUrl();
  const [queryParams] = useQueryParams();
  const [visitorName, setVisitorName] = useState({ firstName: null, lastName: null });
  const [photo, setPhoto] = useState({ src: null, takenAt: null, submitted: false, skipped: false });
  const [isCovidWaiverPassed, setIsCovidWaiverPassed] = useState(false);
  const [host, setHost] = useState(null);
  const { settings, setupError, isSetupLoading } = useSettingsApi(deviceId, queryParams);
  const { lookupResponse, lookupError, lookingUp, callLookupApi } = useLookupApi();
  useLocalization(settings?.locale);

  const registration = parseRegistration(lookupResponse);
  const visitor = parseVisitor(lookupResponse);
  const isVisitorFormComplete = Boolean(lookupResponse);
  const isPhotoComplete = checkIsCheckInPhotoComplete(photo, settings, visitor);
  const shouldSkipAgreementsStep = checkIsAgreementsStepSkipped(
    settings?.defaultAgreements,
    settings?.agreementsEnabled,
  );
  const isCovidWaiverComplete =
    checkIsCovidWaiverSigned(settings?.defaultAgreements, registration?.signatures) ||
    isCovidWaiverPassed ||
    shouldSkipAgreementsStep;
  const isHostComplete = checkIsHostComplete(host, settings, registration);
  const allStepsPassed = isVisitorFormComplete && isPhotoComplete && isCovidWaiverComplete && isHostComplete;
  const { uploadedPhotoResponse, uploadingPhoto, uploadedPhotoError } = usePhotoUploadApi(allStepsPassed, photo);
  const shouldUseCheckIn = allStepsPassed && (uploadedPhotoResponse || !photo.submitted);
  const { checkedIn, checkInError, checkingIn } = useCheckInApi(
    shouldUseCheckIn,
    uploadedPhotoResponse,
    deviceId,
    queryParams,
    settings,
    registration,
    visitorName,
    photo,
    isCovidWaiverPassed,
    host,
  );
  useCheckInNavigation(settings, isVisitorFormComplete, isPhotoComplete, isCovidWaiverComplete, checkedIn, queryParams);
  const match = useRoutes(routes);
  const loader = (isSetupLoading || uploadingPhoto || checkingIn) && <Loader className="loader" />;
  const notFound = (setupError || lookupError || uploadedPhotoError || checkInError || !match) && <NotFound />;
  return (
    loader ||
    notFound ||
    match({
      ...settings,
      deviceId,
      setVisitorName,
      registrationId: registration?.id,
      visitorId: visitor?.id,
      lookingUp,
      callLookupApi,
      photo,
      setPhoto,
      setIsCovidWaiverPassed,
      setHost,
      visitorName,
      host,
    })
  );
};

export default CheckIn;
