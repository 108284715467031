import { envAuth } from 'api/constants';

const encodeCredentials = (credentials) => Buffer.from(credentials, 'utf8').toString('base64');

export const getPresignCredentials = (id, { token }) => encodeCredentials(`${id}:${token}`);

export const getCheckInCredentials = (id, { expiration, signature }) =>
  encodeCredentials(`${id}.${expiration}:${signature}`);

export const getOptions = (method, credentials, body) => {
  return {
    method,
    headers: new Headers({
      Authorization: `Basic ${credentials}`,
      'Content-Type': 'application/json;charset=utf-8',
      ...(envAuth && { 'X-Env-Authentication': envAuth }),
    }),
    body,
  };
};

export const getImageOptions = (body) => {
  return {
    method: 'POST',
    headers: new Headers({
      ...(envAuth && { 'X-Env-Authentication': envAuth }),
    }),
    body,
  };
};
