import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import 'components/common/Photo/PhotoCapture.scss';
import PhotoCapture from 'components/common/Photo/PhotoCapture';
import PhotoUpload from 'components/common/Photo/PhotoUpload';
import ProgressBar from 'components/common/ProgressBar/ProgressBar';
import CtaButton from 'components/common/CtaButton/CtaButton';

function detectMobile() {
  const deviceMatches = [/Android/i, /webOS/i, /iPhone/i, /iPad/i, /iPod/i, /BlackBerry/i, /Windows Phone/i];
  return deviceMatches.some((match) => navigator.userAgent.match(match));
}

const Photo = (props) => {
  const {
    photo,
    setPhoto,
    isSending,
    photoRequired,
    registrationType,
    skipPhoto,
    submitPhoto,
    setCameraDisabled,
    trackTakePhoto,
    trackRetake,
  } = props;
  const { t } = useTranslation();

  return (
    <div className="photo main-section">
      <div className="ray-grid">
        <div className="ray-grid__cell--span-full ray-grid__cell--span-8-desktop">
          <h1>
            <span className="ray-text--h1">{t('photo.title')}</span>
          </h1>
          <ProgressBar stepName="Photo" registrationType={registrationType} />
          <p className="ray-text--body">{t('photo.description')}</p>
          {detectMobile() ? (
            <PhotoUpload
              setPhoto={setPhoto}
              photoSrc={photo.src}
              trackTakePhoto={trackTakePhoto}
              trackRetake={trackRetake}
            />
          ) : (
            <PhotoCapture
              photoSrc={photo.src}
              setPhoto={setPhoto}
              setCameraDisabled={setCameraDisabled}
              trackTakePhoto={trackTakePhoto}
              trackRetake={trackRetake}
            />
          )}
          <div>
            {!photoRequired && (
              <CtaButton
                onClick={skipPhoto}
                disabled={isSending}
                value={t('photo.skip')}
                classNames="ray-button--secondary photo__button--secondary"
              />
            )}
            <CtaButton onClick={submitPhoto} disabled={!photo.src} isLoading={isSending} value={t('next')} />
          </div>
        </div>
      </div>
    </div>
  );
};

Photo.propTypes = {
  photo: PropTypes.shape({
    src: PropTypes.string,
  }),
  setPhoto: PropTypes.func,
  isSending: PropTypes.bool,
  photoRequired: PropTypes.bool,
  registrationType: PropTypes.string,
  skipPhoto: PropTypes.func,
  submitPhoto: PropTypes.func,
  setCameraDisabled: PropTypes.func,
  trackTakePhoto: PropTypes.func,
  trackRetake: PropTypes.func,
};

export default Photo;
