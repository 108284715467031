export const checkIsCovidWaiverSigned = (agreements, signatures) => {
  const waiver = agreements?.length > 0 && agreements[0];
  const waiverSigned =
    signatures?.length > 0 && signatures.find((signature) => signature?.['agreement_id'] === waiver?.id);

  return waiver && waiverSigned;
};

export const checkIsAgreementsStepSkipped = (agreements, agreementsEnabled) =>
  !agreementsEnabled || !agreements?.length;
