import { ReactComponent as Loader } from 'assets/loader.svg';
import React from 'react';
import PropTypes from 'prop-types';

const CtaButton = (props) => {
  const { disabled, isLoading, value, classNames, onClick } = props;

  return (
    <button
      disabled={disabled}
      className={`ray-button ray-button--primary button-loader-container ${classNames}`}
      type="submit"
      onClick={onClick}
    >
      {isLoading && <Loader className="button-loader" />}
      <span {...(isLoading && { style: { visibility: 'hidden' } })}>{value}</span>
    </button>
  );
};

CtaButton.propTypes = {
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  value: PropTypes.string,
  classNames: PropTypes.string,
  onClick: PropTypes.func,
};

export default CtaButton;
