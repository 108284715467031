import { useEffect, useState } from 'react';

import { checkIsDateValid } from 'utils/date';

export const usePresignValidation = (setupData, setIsDateValid) => {
  const [error, setError] = useState(null);

  useEffect(() => {
    if (setupData) {
      const { registration } = setupData;
      const isValid = checkIsDateValid(registration.expected_at);
      if (isValid) {
        setIsDateValid(isValid);
      } else {
        setError('Visit date expired.');
      }
    }
  }, [setupData, setIsDateValid, setError]);

  return { error };
};
