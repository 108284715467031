const checkIsPhotoPrefilled = (visitor, registrationDate, rememberPhoto) => {
  if (!visitor) {
    return false;
  }
  const photoRememberedForever = rememberPhoto === 0;
  const photoNotExpired = photoRememberedForever || new Date(visitor.photoExpiresAt) > registrationDate;
  return !visitor.takePhoto && photoNotExpired;
};

const checkIsPhotoComplete = (photo, visitor, registrationDate, rememberPhoto, photoEnabled) => {
  const photoPassed = photo.skipped || photo.submitted;
  const photoPrefilled = checkIsPhotoPrefilled(visitor, registrationDate, rememberPhoto);
  return !photoEnabled || photoPassed || photoPrefilled;
};

export const checkIsPresignPhotoComplete = (photo, registration, visitor) => {
  if (!registration) {
    return false;
  }
  const {
    expectedAt,
    location: { rememberPhoto, photoEnabled },
  } = registration;
  const registrationDate = new Date(expectedAt);
  return checkIsPhotoComplete(photo, visitor, registrationDate, rememberPhoto, photoEnabled);
};

export const checkIsCheckInPhotoComplete = (photo, settings, visitor) => {
  if (!settings) {
    return false;
  }
  const { photoEnabled, rememberPhoto } = settings;
  const registrationDate = new Date();

  return checkIsPhotoComplete(photo, visitor, registrationDate, rememberPhoto, photoEnabled);
};
